import { AxiosInstance, AxiosResponse } from 'axios';
import { newRefreshToken } from './newRefreshToken';
import store from '../../store';
import actions from '../../actions';
import { CustomAxiosRequestConfig } from './https';

let accessToken = localStorage['token'];
let tokenTimestamp = localStorage['token-ts'] && parseInt(localStorage['token-ts']);

export const _saveToken = (responseToken: TokenData) => {
    const now = Date.now();
    localStorage.setItem('token', responseToken.access);
    localStorage.setItem('token-refresh', responseToken.refresh);
    localStorage.setItem('token-ts', now.toString());
    accessToken = responseToken.access;
    tokenTimestamp = now;
};

export const _clearToken = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('token-refresh');
    localStorage.removeItem('token-ts');
};

interface QueueItem {
    resolve: (value: unknown) => void;
    reject: (reason?: unknown) => void;
}

/**
 * shouldIntercept
 * ===============
 * @description check if the request should be intercepted
 * @param error - axios error
 * @returns boolean - true if the request response is error 401
 */
const shouldIntercept = (error: any) => {
    try {
        return error.response.status === 401;
    } catch (e) {
        return false;
    }
};

interface TokenData {
    access: string;
    refresh: string;
}

/**
 * setTokenData
 * ===============
 * @description set token data in local storage and set authenticated true
 * @param tokenData - access and refresh token
 * @param axiosClient - axios instance
 * @returns void
 */
const setTokenData = async (tokenData: TokenData, axiosClient: AxiosInstance) => {
    console.log('[Axios interceptor] Updating tokens', { tokenData });
    _saveToken(tokenData);
};

/**
 * handleTokenRefresh
 * ==================
 * @description refresh access token using refresh token and set new tokens in local storage
 * @returns promise with access and refresh token
 */
const handleTokenRefresh = async () => {
    console.log('[Axios interceptor] Refreshing token');
    // eslint-disable-next-line no-async-promise-executor
    return new Promise<TokenData>(async (resolve, reject) => {
        try {
            /*
             * Note:
             * - MIN_TIME_TO_REFRESH_TOKEN is equal to 20 min (in milliseconds)
             * - MAX_TIME_TO_REFRESH_TOKEN is equal to 30 min (in milliseconds)
             *
             * log out the user if the token is greater MAX_TIME_TO_REFRESH_TOKEN
             * */
            // const now = Date.now();
            // const refreshable =
            // now - tokenTimestamp > settings.MIN_TIME_TO_REFRESH_TOKEN &&
            // now - tokenTimestamp < settings.MAX_TIME_TO_REFRESH_TOKEN;
            const refreshTokenResponse = await newRefreshToken();
            const refreshToken = localStorage.getItem('token-refresh');
            const newAccessToken = refreshTokenResponse.access;
            console.log('[Axios interceptor] Old and new accesses', {
                oldAccess: accessToken,
                newAccess: newAccessToken,
                equal: accessToken === newAccessToken,
            });
            if (!newAccessToken || !refreshToken) {
                throw new Error(
                    `could not solve tokens: access: ${newAccessToken} / refresh: ${refreshToken}`,
                );
            }
            const tokenData: TokenData = {
                access: newAccessToken,
                refresh: refreshToken,
            };
            resolve(tokenData);
        } catch (err: unknown) {
            console.log('[Axios interceptor] Error refreshing, logging out...');
            _clearToken();
            store.store.dispatch(actions.session.logout());
            reject(err);
        }
    });
};

/**
 * axiosInterceptor
 * ===============
 * @description attachs to a given axios instance an interceptor for refreshing tokens
 * @param axiosClient - axios instance
 * @param customOptions - custom options for axios interceptor if needed. Not used in this project
 * @returns void
 */
export const axiosInterceptorHelper = (axiosClient: AxiosInstance, customOptions = {}) => {
    let isRefreshing = false;
    let failedQueue: QueueItem[] = [];

    // add token header if has it, request that do not need it wont matter.
    axiosClient.interceptors.request.use(
        async (config: CustomAxiosRequestConfig) => {
            const newConfig = { ...config };
            accessToken = localStorage.getItem('token');
            if (accessToken && !config.noAuth) {
                newConfig.headers = {
                    ...config.headers,
                    'Content-Type': 'application/json',
                    accept: 'application/json',
                    Authorization: `Bearer ${accessToken}`,
                };
            }
            delete newConfig.noAuth;
            return newConfig;
        },
        (error) => Promise.reject(error),
    );

    const options = {
        handleTokenRefresh,
        setTokenData,
        shouldIntercept,
        ...customOptions,
    };

    /**
     * processQueue
     * ===============
     * @description process queue of requests that are waiting for the token to refresh
     * @param error - axios error
     * @param token - access token
     * @returns void
     */
    const processQueue = (error: unknown, token: string | null) => {
        console.log('[Axios interceptor] Processing queue (resolving or rejecting)', {
            error,
            token,
        });
        failedQueue.reverse().forEach((prom) => {
            if (error) prom.reject(error);
            else prom.resolve(token);
        });
        failedQueue = [];
    };

    /**
     * interceptor
     * ===============
     * @description axios interceptor for refreshing tokens
     * @param error - axios error
     * @returns promise with access and refresh token
     */
    const interceptor = (error: any): Promise<unknown> => {
        if (!options.shouldIntercept(error)) {
            return Promise.reject(error);
        }
        if (error.config._retry || error.config._queued) {
            return Promise.reject(error);
        }
        const originalRequest = error.config;

        // if is refreshing, add request to queue
        if (isRefreshing) {
            return new Promise((resolve, reject) => {
                failedQueue.push({ resolve, reject });
            })
                .then(() => {
                    originalRequest._queued = true;
                    return axiosClient.request(originalRequest);
                })
                .catch(() => {
                    // Ignore refresh token request's "err" and return actual "error" for the original request
                    return Promise.reject(error);
                });
        }

        originalRequest._retry = true;
        isRefreshing = true;

        // refresh token
        return new Promise<AxiosResponse<string>>((resolve, reject) => {
            options.handleTokenRefresh
                .call(options.handleTokenRefresh)
                .then((tokenData) => {
                    // insted of options.attachTokenToRequest
                    options.setTokenData(tokenData, axiosClient);
                    processQueue(null, tokenData.access);
                    resolve(axiosClient.request(originalRequest));
                })
                .catch((err: unknown) => {
                    processQueue(err, null);
                    reject(err);
                })
                .finally(() => {
                    isRefreshing = false;
                });
        });
    };

    // add interceptor
    axiosClient.interceptors.response.use(undefined, interceptor);
};
