import { AxiosError, AxiosRequestConfig, Method } from 'axios';
import { axiosInstance, noInterceptorAxiosInstance } from './axiosInstance';

type ParamsType = Record<string, string> | URLSearchParams;

export interface CustomAxiosRequestConfig extends AxiosRequestConfig {
    noAuth?: boolean;
}

const __httpRequest = async <ResponseData = any, DataType = any>({
    method,
    url,
    data,
    params,
    headers,
    config,
    extraOptions,
}: {
    method: Method;
    url: string;
    data?: DataType;
    params?: ParamsType;
    headers?: any;
    config?: AxiosRequestConfig;
    extraOptions?: {
        noAuth: boolean;
        doNotLogout: boolean;
    };
}) => {
    const token = localStorage.getItem('token');
    const instance = extraOptions?.doNotLogout ? noInterceptorAxiosInstance : axiosInstance;
    try {
        const response = await instance.request({
            method,
            url,
            data,
            params: { ...params, ...config?.params },
            headers:
                token && !extraOptions?.noAuth
                    ? {
                          Authorization: `Bearer ${token}`,
                          ...headers,
                          ...config?.headers,
                      }
                    : { ...headers, ...config?.headers },
            noAuth: extraOptions?.noAuth,
        } as CustomAxiosRequestConfig);
        return response.data;
    } catch (e) {
        throw e as AxiosError;
    }
};

export const basic_get = (url: string, headers: any) =>
    __httpRequest({
        method: 'GET',
        url,
        headers: {
            'Content-Type': 'application/json',
            ...(headers ? headers : {}),
        },
    });

export const get = <D>(url: string, withOutToken?: boolean, params?: ParamsType) =>
    __httpRequest<D, any>({
        method: 'GET',
        url,
        params,
        extraOptions: {
            noAuth: !!withOutToken,
            doNotLogout: false,
        },
    });

export const basic_post = <D>(url: string, body: any) =>
    __httpRequest<D, any>({ method: 'POST', url, data: body });

export const axios_post = <D>(url: string, body: any, headers: any) =>
    __httpRequest<D, any>({
        method: 'POST',
        url,
        data: body,
        headers: {
            'Content-Type': 'application/json',
            ...(headers ? headers : {}),
        },
        extraOptions: {
            noAuth: false,
            doNotLogout: true,
        },
    });

export const post = <D>(url: string, body: any, config = {}, noAuth?: boolean) => {
    return __httpRequest<D, any>({
        method: 'POST',
        url,
        data: body,
        config,
        extraOptions: {
            noAuth: !!noAuth,
            doNotLogout: true,
        },
    });
};

export const put = <D>(url: string, body: any, config?: any, withOutToken?: boolean) =>
    __httpRequest<D, any>({
        method: 'PUT',
        url,
        data: body,
        config,
        extraOptions: {
            noAuth: !!withOutToken,
            doNotLogout: true,
        },
    });

export const deleteRequest = <D>(url: string, body: any, config = {}) =>
    __httpRequest<D, any>({
        method: 'DELETE',
        url,
        data: body,
        config,
    });
